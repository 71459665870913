import { Flex } from "@jewlr/storybook/core"
import styled from "styled-components"

const ItemContainer = styled(Flex).attrs((props) => ({
  flexDirection: "column",
  mb: { _: 3, tablet: "30px" },
  padding: "0 8px",
  textAlign: "center",
  ...props,
}))`
  position: relative;
`
export default ItemContainer
