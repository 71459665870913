import { Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const ProductName = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: none;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 6px;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  text-overflow: ellipsis;
  ${(props) => props.theme.mediaQueries.desktop`
    display: -webkit-box;
  `}
`

const ProductDescription = ({ productName }) => {
  return <ProductName title={productName}>{productName}</ProductName>
}

ProductDescription.propTypes = {
  productName: PropTypes.string,
}

export default ProductDescription
